import "./react_dates_overrides.css";

import * as Actions from "./store/actions";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { dailyQuestRuleTypes, raffleRuleTypes } from "@tapchamps/Types";

import { DateRangePicker } from "react-dates";
import { Multiselect } from "multiselect-react-dropdown";
import { TapchampsUtils } from "@tapchamps";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "app/store/withReducer";
import exportFromJSON from "export-from-json";
import { Line } from "react-chartjs-2";
import _ from "lodash";
import { AppSwitch } from "@coreui/react";
import moment from "moment";

const multiselectStyleGame = {
  multiselectContainer: {
    minWidth: 300,
  },
  searchBox: {
    fontSize: 12,
    height: 35,
    padding: 3,
  },
  chips: {
    fontSize: 10,
  },
  option: {
    fontSize: 12,
  },
};

const GROUP_BY = [
  {
    id: "created_at_date",
    name: "Date",
  },
  {
    id: "bucket",
    name: "Age Bucket",
  },
  {
    id: "gender",
    name: "Gender",
  },
  {
    id: "countrycode",
    name: "Country Code",
  },
];

const socialChartOpts = {
  tooltips: {
    mode: "index",
    intersect: false,
  },
  hover: {
    mode: "nearest",
    intersect: true,
  },
  responsive: true,
  maintainAspectRatio: true,
  legend: {
    display: true,
  },
  scales: {
    xAxes: [
      {
        display: true,
      },
    ],
    yAxes: [
      {
        display: true,
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

class UpliftGamesStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      game: "",
      country: "",
      groupBy: "",
      fields: window.localStorage.getItem("fieldsCampaigns")
        ? window.localStorage.getItem("fieldsCampaigns").split(",")
        : [
            "name",
            "revenue",
            "purchases",
            "avg_engagement_time",
            "minutes",
            "downloads",
            "retention_ratios",
          ],
      fieldsToDisplay: [],
      showChartsOnApply: false,
      showCharts: false,
      focusedInput: null,
      startDate: null,
      endDate: null,
      selectedCountry: "",
      canApplyFilter: true,
      sizePerPage: 10,
      activeTab: "purchases",
    };
  }

  componentDidMount() {
    const {
      upliftgamesstats: { startDate, endDate },
    } = this.props;
    this.setState({ startDate, endDate });
    this.props.dispatch(Actions.getCountriesForSelect());
    this.props.dispatch(Actions.getGamesForSelect());
  }

  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  onCountrySelect(event) {
    if (event.target.value === "Country") {
      this.setState({ selectedCountry: "" });
    } else {
      this.setState({ selectedCountry: event.target.value });
    }
  }

  onGameSelect(selectedList, selectedItem) {
    if (selectedList.length > 0) {
      this.setState({ game: selectedItem.id, canApplyFilter: true });
    }
  }

  onGameRemove(selectedList, removedItem) {
    this.setState({ game: "" });
  }

  onGroupBySelect(selectedList, selectedItem) {
    this.setState((prevState) => {
      const currentGroupBy =
        prevState.groupBy && prevState.groupBy.length
          ? prevState.groupBy.split(",")
          : [];
      currentGroupBy.push(selectedItem.id);
      return { groupBy: currentGroupBy.join(",") };
    });
  }

  onGroupByRemove(selectedList, selectedItem) {
    this.setState((prevState) => {
      const currentGroupBy = prevState.groupBy
        .split(",")
        .filter((item) => item !== selectedItem.id);
      return { groupBy: currentGroupBy.join(",") };
    });
  }

  onDownloadReportClick(data) {
    let fields = {
      revenue: "Revenue",
      purchases: "Purchases",
      minutes: "Minutes",
      downloads: "Downloads",
      bucket: "Age Bucket",
      gender: "Gender",
      countrycode: "Country",
      created_at_date: "Date",
      retention_ratio_day_0: "Retention D0 (%)",
      retention_ratio_day_1: "Retention D1 (%)",
      retention_ratio_day_2: "Retention D2 (%)",
      retention_ratio_day_3: "Retention D3 (%)",
      retention_ratio_day_4: "Retention D4 (%)",
      retention_ratio_day_5: "Retention D5 (%)",
      retention_ratio_day_6: "Retention D6 (%)",
      retention_ratio_day_7: "Retention D7 (%)",
    };
    let fileName = "stats";

    const startDateFormat = new Date(this.state.startDate).toLocaleDateString(
      "en-US"
    );
    const endDateFormat = new Date(this.state.endDate).toLocaleDateString(
      "en-US"
    );

    fileName += "_" + startDateFormat + "_" + endDateFormat;
    if (this.state.game) {
      fileName += "_" + this.state.game;
    }
    fileName += "_" + +new Date();

    exportFromJSON({
      data: data,
      fileName: fileName,
      fields: fields,
      exportType: exportFromJSON.types.csv,
    });
  }

  onApplyFilterClick() {
    if (!this.state.game) {
      this.setState({ canApplyFilter: false });
      return;
    }
    this.setState({
      fieldsToDisplay: [...this.state.fields],
      showChartsOnApply: this.state.showCharts,
    });
    this.props.dispatch(
      Actions.getBuckets(
        this.state.game,
        this.state.groupBy,
        this.state.fields,
        this.state.selectedCountry
      )
    );
    if (this.state.showCharts) {
      this.props.dispatch(
        Actions.getCharts(this.state.game, this.state.selectedCountry)
      );
    }
  }

  toCommasFormat = (num) => {
    return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0";
  };

  dateFormat = (cell, row) => {
    return moment(cell).format("lll");
  };

  renderTypeText(cell, row) {
    const type = parseInt(cell);
    return dailyQuestRuleTypes[type];
  }

  renderRaffleRulesText(cell, row) {
    if (!cell) return "";

    let text = [];
    cell.forEach((item) => {
      const ruleType = raffleRuleTypes[parseInt(item)];
      if (ruleType) {
        text.push(ruleType);
      }
    });
    return text.join(", ");
  }

  formatCell = (cell, formatType) => {
    switch (formatType) {
      case "currency":
        return `$ ${cell ? cell.toFixed(2).toLocaleString() : "0.00"}`;
      case "integer":
        return `${cell ? cell.toFixed(0).toLocaleString() : "0"}`;
      case "retention":
        return `${cell ? (cell * 100).toFixed(2) : "0"}%`;
      case "number":
      default:
        return cell ? cell.toFixed(2).toLocaleString() : "0.00";
    }
  };

  valueFormatter = (cell, row, fieldName, formatType = "number") => {
    const prevValue = row.prev ? row.prev[fieldName] : null;
    if (prevValue == null) return this.formatCell(cell, formatType);

    const diff = ((cell - prevValue) / prevValue) * 100;
    const formattedDiff = diff.toFixed(2);
    const color = diff > 0 ? "green" : "red";
    const arrow = diff > 0 ? "↑" : "↓";

    return (
      <span style={{ color }}>
        {this.formatCell(cell, formatType)} {arrow} ({formattedDiff}%)
      </span>
    );
  };

  getTableKey = (data) => {
    if (data && data[0]) {
      if (data[0].game_id) {
        return "game_id";
      } else if (data[0].category) {
        return "category";
      } else if (data[0].created_at_date) {
        return "created_at_date";
      } else if (data[0].country_code) {
        return "country_code";
      } else if (data[0].games_amount) {
        return "games_amount";
      }
    } else {
      return null;
    }
  };

  onSizePerPageList(sizePerPage) {
    this.setState({ sizePerPage: sizePerPage });
  }

  checkFields = async (event) => {
    let fields = _.cloneDeep(this.state.fields);
    if (event.target.checked) {
      fields.push(event.target.name);
    } else {
      fields.splice(fields.indexOf(event.target.name), 1);
    }
    window.localStorage.setItem("fieldsCampaigns", fields);
    this.setState({ fields });
  };

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  render() {
    const { loading } = this.props;
    if (loading) {
      return loading();
    }

    const canViewBreakdown = TapchampsUtils.hasPermission(
      ["admin", "breakdownstats-viewer"],
      this.props.user.role
    );

    let {
      upliftgamesstats: {
        data: bucket_data,
        loading: bucket_loading,
        gamesforselect,
        countriesforselect,
        chartsData,
      },
    } = this.props;

    gamesforselect = gamesforselect.map((game) => ({
      ...game,
      displayName: `${game.name} (${game.platform})`,
    }));

    const showTableAndCharts = !!bucket_data?.data;

    let chartData = chartsData.data;
    chartData = chartData ? chartData[0] : [];
    let chartDatePrev = chartsData.dataPrev;
    chartDatePrev = chartDatePrev ? chartDatePrev[0] : [];
    let chartDataArray = [];
    let chartDataPrevArray = [];
    let bucketChartData = {};
    if (chartData) {
      chartDataArray = [
        chartData.retention_ratio_day_0,
        chartData.retention_ratio_day_1,
        chartData.retention_ratio_day_2,
        chartData.retention_ratio_day_3,
        chartData.retention_ratio_day_4,
        chartData.retention_ratio_day_5,
        chartData.retention_ratio_day_6,
        chartData.retention_ratio_day_7,
      ];

      chartDataPrevArray = [
        chartDatePrev.retention_ratio_day_0,
        chartDatePrev.retention_ratio_day_1,
        chartDatePrev.retention_ratio_day_2,
        chartDatePrev.retention_ratio_day_3,
        chartDatePrev.retention_ratio_day_4,
        chartDatePrev.retention_ratio_day_5,
        chartDatePrev.retention_ratio_day_6,
        chartDatePrev.retention_ratio_day_7,
      ];

      bucketChartData = {
        datasets: [
          {
            label: "Retention",
            data: chartDataArray,
            backgroundColor: "#1E90FF",
            borderColor: "#1E90FF",
            fill: false,
          },
          {
            label: "Retention Previous",
            data: chartDataPrevArray,
            backgroundColor: "#D3D3D3",
            borderColor: "#D3D3D3",
            fill: false,
          },
        ],
        labels: ["d0", "d1", "d2", "d3", "d4", "d5", "d6", "d7"],
      };
    }

    let chartParamsData = chartsData.paramsData?.sort(
      (a, b) => new Date(a.created_at_date) - new Date(b.created_at_date)
    );
    let chartParamsDatePrev = chartsData.paramsDataPrev?.sort(
      (a, b) => new Date(a.created_at_date) - new Date(b.created_at_date)
    );

    let paramsChartData = {};
    const { activeTab } = this.state;

    if (chartParamsData) {
      paramsChartData = {
        datasets: [
          {
            label: `${
              activeTab.charAt(0).toUpperCase() + activeTab.slice(1)
            } (Current)`,
            data: chartParamsData.map((item) => item[activeTab]),
            backgroundColor: "#1E90FF",
            borderColor: "#1E90FF",
            fill: false,
          },
          {
            label: `${
              activeTab.charAt(0).toUpperCase() + activeTab.slice(1)
            } (Previous)`,
            data: chartParamsDatePrev.map((item) => item[activeTab]),
            backgroundColor: "#D3D3D3",
            borderColor: "#D3D3D3",
            fill: false,
          },
        ],
        labels: chartParamsData.map((item, index) => {
          const currentLabel = item.created_at_date;
          const prevLabel =
            chartParamsDatePrev[index]?.created_at_date || "N/A";
          return `${prevLabel} / ${currentLabel}`;
        }),
      };
    }

    return (
      canViewBreakdown && (
        <>
          <Row>
            <Col>
              <Card>
                <CardHeader>Report Settings</CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <div className="float-left  mb-2">
                        <Row>
                          <Col>
                            <Label for="searchGameForm">Game</Label>
                            <FormGroup id="searchGameForm">
                              <Multiselect
                                id="search1"
                                selectionLimit={1}
                                loading={loading}
                                style={multiselectStyleGame}
                                options={gamesforselect}
                                placeholder="Select Game"
                                onSelect={this.onGameSelect.bind(this)}
                                onRemove={this.onGameRemove.bind(this)}
                                displayValue="displayName"
                              />
                            </FormGroup>
                            {!this.state.canApplyFilter && (
                              <div style={{ color: "red", marginTop: "-10px" }}>
                                Please select a game.
                              </div>
                            )}
                          </Col>
                          <Col>
                            <Label for="countryForm">Country</Label>
                            <FormGroup id="countryForm">
                              <Input
                                type="select"
                                id="country"
                                name="country"
                                placeholder="Select Country"
                                value={this.state.selectedCountry}
                                onChange={this.onCountrySelect.bind(this)}
                              >
                                <option value="Country">Country</option>
                                {countriesforselect.map((data, i) => {
                                  return (
                                    <option key={i} value={data.countrycode}>
                                      {data.countrycode}
                                    </option>
                                  );
                                })}
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col>
                            <Label htmlFor="showCharts">Include Charts</Label>
                            <div>
                              <AppSwitch
                                id="showCharts"
                                name="showCharts"
                                checked={this.state.showCharts}
                                onChange={(e) => {
                                  this.setState({
                                    showCharts: e.target.checked,
                                  });
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col>
                      <div className="float-right">
                        <Label for="reportDateRange">Dates</Label>
                        <FormGroup id="reportDateRange" className="mb-0">
                          <DateRangePicker
                            minimumNights={0}
                            isOutsideRange={() => false}
                            startDate={this.state.startDate}
                            startDateId="startDate"
                            endDate={this.state.endDate}
                            endDateId="endDate"
                            small
                            onDatesChange={({ startDate, endDate }) =>
                              this.setState({ startDate, endDate })
                            }
                            focusedInput={this.state.focusedInput}
                            onFocusChange={(focusedInput) => {
                              this.setState({ focusedInput });
                              if (focusedInput === null) {
                                setTimeout(() => {
                                  this.props.dispatch(
                                    Actions.setDateRange(
                                      this.state.game,
                                      this.state.groupBy,
                                      this.state.startDate,
                                      this.state.endDate
                                    )
                                  );
                                }, 500);
                              }
                            }}
                            orientation={this.state.orientation}
                            openDirection={this.state.openDirection}
                          />
                        </FormGroup>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="float-left">
                        <Label for="groupbyform">Group By</Label>
                        <FormGroup id="groupbyform">
                          <Multiselect
                            id="groupby"
                            loading={loading}
                            style={multiselectStyleGame}
                            options={GROUP_BY}
                            placeholder="Select Group By"
                            onSelect={this.onGroupBySelect.bind(this)}
                            onRemove={this.onGroupByRemove.bind(this)}
                            displayValue="name"
                          />
                        </FormGroup>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="mb-2">Fields</div>
                      <FormGroup check inline>
                        <Input
                          id="gamenamecheck"
                          name="name"
                          type="checkbox"
                          onChange={this.checkFields}
                          checked={this.state.fields.indexOf("name") !== -1}
                        />
                        <Label check for="gamenamecheck">
                          Game Name
                        </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Input
                          id="gametype"
                          name="gametype"
                          type="checkbox"
                          onChange={this.checkFields}
                          checked={this.state.fields.indexOf("gametype") !== -1}
                        />
                        <Label check for="gametype">
                          Game Type
                        </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Input
                          id="downloads"
                          name="downloads"
                          type="checkbox"
                          onChange={this.checkFields}
                          checked={
                            this.state.fields.indexOf("downloads") !== -1
                          }
                        />
                        <Label check for="downloads">
                          Downloads
                        </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Input
                          id="purchases"
                          name="purchases"
                          type="checkbox"
                          onChange={this.checkFields}
                          checked={
                            this.state.fields.indexOf("purchases") !== -1
                          }
                        />
                        <Label check for="purchases">
                          Purchases
                        </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Input
                          id="revenue"
                          name="revenue"
                          type="checkbox"
                          onChange={this.checkFields}
                          checked={this.state.fields.indexOf("revenue") !== -1}
                        />
                        <Label check for="revenue">
                          Revenue
                        </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Input
                          id="minutes"
                          name="minutes"
                          type="checkbox"
                          onChange={this.checkFields}
                          checked={this.state.fields.indexOf("minutes") !== -1}
                        />
                        <Label check for="minutes">
                          Minutes
                        </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Input
                          id="retention_ratios"
                          name="retention_ratios"
                          type="checkbox"
                          onChange={this.checkFields}
                          checked={
                            this.state.fields.indexOf("retention_ratios") !== -1
                          }
                        />
                        <Label check for="retention_ratios">
                          Retention
                        </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Input
                          id="avg_engagement_time"
                          name="avg_engagement_time"
                          type="checkbox"
                          onChange={this.checkFields}
                          checked={
                            this.state.fields.indexOf("avg_engagement_time") !==
                            -1
                          }
                        />
                        <Label check for="avg_engagement_time">
                          Average Engagement Time per User
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Button
                    className="float-right"
                    color="success"
                    disabled={!this.state.canApplyFilter}
                    onClick={(e) => {
                      this.onApplyFilterClick();
                    }}
                  >
                    {bucket_loading && <Spinner color="white" size="sm" />}
                    {!bucket_loading && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-funnel"
                        viewBox="0 0 16 16"
                      >
                        <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z" />
                      </svg>
                    )}{" "}
                    Apply filters
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>

          {showTableAndCharts && (
            <div>
              <Row>
                <Col>
                  {bucket_loading === true ? (
                    <Spinner color="primary" size="md" />
                  ) : (
                    <>
                      {bucket_data.mergedData.length > 0 ? (
                        <>
                          <Card>
                            <CardHeader>
                              Stats
                              <Button
                                className="float-right"
                                color="outline-success"
                                disabled={bucket_loading}
                                onClick={(e) => {
                                  this.onDownloadReportClick(bucket_data.data);
                                }}
                              >
                                {bucket_loading && (
                                  <Spinner color="success" size="sm" />
                                )}
                                {!bucket_loading && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-download"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                  </svg>
                                )}{" "}
                                Export CSV
                              </Button>
                            </CardHeader>
                            <CardBody>
                              <BootstrapTable
                                trClassName="customBootstrapTable"
                                data={
                                  bucket_loading ? [] : bucket_data.mergedData
                                }
                                remote={false}
                                pagination={true}
                                striped
                                options={{
                                  defaultSortName: "downloads",
                                  defaultSortOrder: "desc",
                                  clearSearch: true,
                                  sizePerPageList: [10, 20, 50, 100],
                                  sizePerPage: this.state.sizePerPage,
                                  onSizePerPageList:
                                    this.onSizePerPageList.bind(this),
                                }}
                              >
                                <TableHeaderColumn
                                  dataField={this.getTableKey(
                                    bucket_data.mergedData
                                  )}
                                  isKey
                                  hidden
                                >
                                  ID
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                  ref="name"
                                  width="250px"
                                  dataField="name"
                                  dataSort={false}
                                  hidden={
                                    !(
                                      !bucket_loading &&
                                      bucket_data.mergedData.length > 0 &&
                                      bucket_data.mergedData[0].name &&
                                      this.state.fieldsToDisplay.includes(
                                        "name"
                                      )
                                    )
                                  }
                                >
                                  Game
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                  ref="gameType"
                                  width="150px"
                                  dataField="gameType"
                                  dataSort={false}
                                  hidden={
                                    !(
                                      !bucket_loading &&
                                      bucket_data.mergedData.length > 0 &&
                                      bucket_data.mergedData[0].gameType &&
                                      this.state.fieldsToDisplay.includes(
                                        "gametype"
                                      )
                                    )
                                  }
                                >
                                  Game Type
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                  ref="created_at_date"
                                  dataField="created_at_date"
                                  width="150px"
                                  dataSort={true}
                                  hidden={
                                    !(
                                      !bucket_loading &&
                                      bucket_data.mergedData.length > 0 &&
                                      bucket_data.mergedData[0].created_at_date
                                    )
                                  }
                                >
                                  Date
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                  ref="bucket"
                                  dataField="bucket"
                                  width="150px"
                                  dataSort={false}
                                  hidden={
                                    !(
                                      !bucket_loading &&
                                      bucket_data.mergedData.length > 0 &&
                                      bucket_data.mergedData[0].bucket
                                    )
                                  }
                                >
                                  Age
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                  ref="gender"
                                  dataField="gender"
                                  width="150px"
                                  dataSort={false}
                                  hidden={
                                    !(
                                      !bucket_loading &&
                                      bucket_data.mergedData.length > 0 &&
                                      bucket_data.mergedData[0].gender
                                    )
                                  }
                                >
                                  Gender
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                  ref="countrycode"
                                  dataField="countrycode"
                                  width="150px"
                                  dataSort={false}
                                  hidden={
                                    !(
                                      !bucket_loading &&
                                      bucket_data.mergedData.length > 0 &&
                                      bucket_data.mergedData[0].countrycode
                                    )
                                  }
                                >
                                  Country Code
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                  ref="downloads"
                                  dataField="downloads"
                                  width="150px"
                                  dataSort={true}
                                  dataFormat={(cell, row) =>
                                    this.valueFormatter(
                                      cell,
                                      row,
                                      "downloads",
                                      "integer"
                                    )
                                  }
                                  hidden={
                                    !(
                                      !bucket_loading &&
                                      bucket_data.mergedData.length > 0 &&
                                      bucket_data.mergedData[0].downloads &&
                                      this.state.fieldsToDisplay.includes(
                                        "downloads"
                                      )
                                    )
                                  }
                                >
                                  Downloads
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                  ref="purchases"
                                  dataField="purchases"
                                  width="150px"
                                  dataSort={true}
                                  dataFormat={(cell, row) =>
                                    this.valueFormatter(
                                      cell,
                                      row,
                                      "purchases",
                                      "integer"
                                    )
                                  }
                                  hidden={
                                    !(
                                      !bucket_loading &&
                                      bucket_data.mergedData.length > 0 &&
                                      bucket_data.mergedData[0].purchases &&
                                      this.state.fieldsToDisplay.includes(
                                        "purchases"
                                      )
                                    )
                                  }
                                >
                                  Purchases
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                  ref="revenue"
                                  width="150px"
                                  dataField="revenue"
                                  dataSort={true}
                                  dataFormat={(cell, row) =>
                                    this.valueFormatter(
                                      cell,
                                      row,
                                      "revenue",
                                      "currency"
                                    )
                                  }
                                  hidden={
                                    !(
                                      !bucket_loading &&
                                      bucket_data.mergedData.length > 0 &&
                                      bucket_data.mergedData[0].revenue &&
                                      this.state.fieldsToDisplay.includes(
                                        "revenue"
                                      )
                                    )
                                  }
                                >
                                  Revenue
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                  ref="minutes"
                                  dataField="minutes"
                                  width="150px"
                                  dataSort={true}
                                  dataFormat={(cell, row) =>
                                    this.valueFormatter(
                                      cell,
                                      row,
                                      "minutes",
                                      "integer"
                                    )
                                  }
                                  hidden={
                                    !(
                                      !bucket_loading &&
                                      bucket_data.mergedData.length > 0 &&
                                      bucket_data.mergedData[0].minutes &&
                                      this.state.fieldsToDisplay.includes(
                                        "minutes"
                                      )
                                    )
                                  }
                                >
                                  Minutes
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                  ref="avg_engagement_time"
                                  dataField="avg_engagement_time"
                                  width="150px"
                                  dataSort={true}
                                  dataFormat={(cell, row) =>
                                    this.valueFormatter(
                                      cell,
                                      row,
                                      "avg_engagement_time",
                                      "number"
                                    )
                                  }
                                  hidden={
                                    !(
                                      !bucket_loading &&
                                      bucket_data.mergedData.length > 0 &&
                                      bucket_data.mergedData[0]
                                        .avg_engagement_time &&
                                      this.state.fieldsToDisplay.includes(
                                        "avg_engagement_time"
                                      )
                                    )
                                  }
                                >
                                  Avg Engagement Time per User
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                  ref="retention_ratio_day_0"
                                  dataField="retention_ratio_day_0"
                                  width="150px"
                                  dataSort={true}
                                  dataFormat={(cell, row) =>
                                    this.valueFormatter(
                                      cell,
                                      row,
                                      "retention_ratio_day_0",
                                      "retention"
                                    )
                                  }
                                  hidden={
                                    !(
                                      !bucket_loading &&
                                      bucket_data.mergedData.length > 0 &&
                                      this.state.fieldsToDisplay.includes(
                                        "retention_ratios"
                                      )
                                    )
                                  }
                                >
                                  Retention D0
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                  ref="retention_ratio_day_1"
                                  dataField="retention_ratio_day_1"
                                  width="150px"
                                  dataSort={true}
                                  dataFormat={(cell, row) =>
                                    this.valueFormatter(
                                      cell,
                                      row,
                                      "retention_ratio_day_1",
                                      "retention"
                                    )
                                  }
                                  hidden={
                                    !(
                                      !bucket_loading &&
                                      bucket_data.mergedData.length > 0 &&
                                      this.state.fieldsToDisplay.includes(
                                        "retention_ratios"
                                      )
                                    )
                                  }
                                >
                                  Retention D1
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                  ref="retention_ratio_day_2"
                                  dataField="retention_ratio_day_2"
                                  width="150px"
                                  dataSort={true}
                                  dataFormat={(cell, row) =>
                                    this.valueFormatter(
                                      cell,
                                      row,
                                      "retention_ratio_day_2",
                                      "retention"
                                    )
                                  }
                                  hidden={
                                    !(
                                      !bucket_loading &&
                                      bucket_data.mergedData.length > 0 &&
                                      this.state.fieldsToDisplay.includes(
                                        "retention_ratios"
                                      )
                                    )
                                  }
                                >
                                  Retention D2
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                  ref="retention_ratio_day_3"
                                  dataField="retention_ratio_day_3"
                                  width="150px"
                                  dataSort={true}
                                  dataFormat={(cell, row) =>
                                    this.valueFormatter(
                                      cell,
                                      row,
                                      "retention_ratio_day_3",
                                      "retention"
                                    )
                                  }
                                  hidden={
                                    !(
                                      !bucket_loading &&
                                      bucket_data.mergedData.length > 0 &&
                                      this.state.fieldsToDisplay.includes(
                                        "retention_ratios"
                                      )
                                    )
                                  }
                                >
                                  Retention D3
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                  ref="retention_ratio_day_4"
                                  dataField="retention_ratio_day_4"
                                  width="150px"
                                  dataSort={true}
                                  dataFormat={(cell, row) =>
                                    this.valueFormatter(
                                      cell,
                                      row,
                                      "retention_ratio_day_4",
                                      "retention"
                                    )
                                  }
                                  hidden={
                                    !(
                                      !bucket_loading &&
                                      bucket_data.mergedData.length > 0 &&
                                      this.state.fieldsToDisplay.includes(
                                        "retention_ratios"
                                      )
                                    )
                                  }
                                >
                                  Retention D4
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                  ref="retention_ratio_day_5"
                                  dataField="retention_ratio_day_5"
                                  width="150px"
                                  dataSort={true}
                                  dataFormat={(cell, row) =>
                                    this.valueFormatter(
                                      cell,
                                      row,
                                      "retention_ratio_day_5",
                                      "retention"
                                    )
                                  }
                                  hidden={
                                    !(
                                      !bucket_loading &&
                                      bucket_data.mergedData.length > 0 &&
                                      this.state.fieldsToDisplay.includes(
                                        "retention_ratios"
                                      )
                                    )
                                  }
                                >
                                  Retention D5
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                  ref="retention_ratio_day_6"
                                  dataField="retention_ratio_day_6"
                                  width="150px"
                                  dataSort={true}
                                  dataFormat={(cell, row) =>
                                    this.valueFormatter(
                                      cell,
                                      row,
                                      "retention_ratio_day_6",
                                      "retention"
                                    )
                                  }
                                  hidden={
                                    !(
                                      !bucket_loading &&
                                      bucket_data.mergedData.length > 0 &&
                                      this.state.fieldsToDisplay.includes(
                                        "retention_ratios"
                                      )
                                    )
                                  }
                                >
                                  Retention D6
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                  ref="retention_ratio_day_7"
                                  dataField="retention_ratio_day_7"
                                  width="150px"
                                  dataSort={true}
                                  dataFormat={(cell, row) =>
                                    this.valueFormatter(
                                      cell,
                                      row,
                                      "retention_ratio_day_7",
                                      "retention"
                                    )
                                  }
                                  hidden={
                                    !(
                                      !bucket_loading &&
                                      bucket_data.mergedData.length > 0 &&
                                      this.state.fieldsToDisplay.includes(
                                        "retention_ratios"
                                      )
                                    )
                                  }
                                >
                                  Retention D7
                                </TableHeaderColumn>
                              </BootstrapTable>
                            </CardBody>
                          </Card>

                          {bucket_data.dailyQuests &&
                            bucket_data.dailyQuests.length > 0 && (
                              <Card>
                                <CardHeader>Daily Quests</CardHeader>
                                <CardBody>
                                  <BootstrapTable
                                    trClassName="customBootstrapTable"
                                    data={
                                      bucket_loading
                                        ? []
                                        : bucket_data.dailyQuests
                                    }
                                    remote={false}
                                    pagination={false}
                                    striped
                                    options={{
                                      defaultSortName: "dateend",
                                      defaultSortOrder: "desc",
                                    }}
                                  >
                                    <TableHeaderColumn
                                      dataField="_id"
                                      isKey
                                      hidden
                                    >
                                      ID
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      ref="name"
                                      dataField="name"
                                      dataSort={true}
                                    >
                                      Name
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      ref="datestart"
                                      dataField="datestart"
                                      dataSort={true}
                                      dataFormat={this.dateFormat}
                                    >
                                      Date Start
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      ref="dateend"
                                      dataField="dateend"
                                      dataSort={true}
                                      dataFormat={this.dateFormat}
                                    >
                                      Date End
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      ref="diamonds"
                                      dataField="diamonds"
                                      dataSort={true}
                                    >
                                      Diamonds
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      ref="type"
                                      dataField="type"
                                      dataSort={true}
                                      dataFormat={this.renderTypeText}
                                    >
                                      Type
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      ref="level"
                                      dataField="level"
                                      dataSort={true}
                                    >
                                      Level
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      ref="minutes"
                                      dataField="minutes"
                                      dataSort={true}
                                    >
                                      Minutes
                                    </TableHeaderColumn>
                                  </BootstrapTable>
                                </CardBody>
                              </Card>
                            )}

                          {bucket_data.contests &&
                            bucket_data.contests.length > 0 && (
                              <Card>
                                <CardHeader>Contests</CardHeader>
                                <CardBody>
                                  <BootstrapTable
                                    trClassName="customBootstrapTable"
                                    data={
                                      bucket_loading ? [] : bucket_data.contests
                                    }
                                    remote={false}
                                    pagination={false}
                                    striped
                                    options={{
                                      defaultSortName: "endDate",
                                      defaultSortOrder: "desc",
                                    }}
                                  >
                                    <TableHeaderColumn
                                      dataField="_id"
                                      isKey
                                      hidden
                                    >
                                      ID
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      ref="name"
                                      dataField="name"
                                      dataSort={true}
                                    >
                                      Name
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      ref="startDate"
                                      dataField="startDate"
                                      dataSort={true}
                                    >
                                      Date Start
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      ref="endDate"
                                      dataField="endDate"
                                      dataSort={true}
                                    >
                                      Date End
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      ref="diamonds"
                                      dataField="country"
                                      dataSort={true}
                                    >
                                      Country
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      ref="prize_variable_units"
                                      dataField="prize_variable_units"
                                      dataSort={true}
                                      dataFormat={this.toCommasFormat}
                                    >
                                      Price 1st
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      ref="prize_variable_units2"
                                      dataField="prize_variable_units2"
                                      dataSort={true}
                                      dataFormat={this.toCommasFormat}
                                    >
                                      Price 2st
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      ref="prize_variable_units3"
                                      dataField="prize_variable_units3"
                                      dataSort={true}
                                      dataFormat={this.toCommasFormat}
                                    >
                                      Price 3st
                                    </TableHeaderColumn>
                                  </BootstrapTable>
                                </CardBody>
                              </Card>
                            )}

                          {bucket_data.raffles &&
                            bucket_data.raffles.length > 0 && (
                              <Card>
                                <CardHeader>Raffles</CardHeader>
                                <CardBody>
                                  <BootstrapTable
                                    trClassName="customBootstrapTable"
                                    data={
                                      bucket_loading ? [] : bucket_data.raffles
                                    }
                                    remote={false}
                                    pagination={false}
                                    striped
                                    options={{
                                      defaultSortName: "endDate",
                                      defaultSortOrder: "desc",
                                    }}
                                  >
                                    <TableHeaderColumn
                                      dataField="_id"
                                      isKey
                                      hidden
                                    >
                                      ID
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      ref="name"
                                      dataField="name"
                                      dataSort={true}
                                    >
                                      Name
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      ref="startDate"
                                      dataField="startDate"
                                      dataSort={true}
                                    >
                                      Date Start
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      ref="endDate"
                                      dataField="endDate"
                                      dataSort={true}
                                    >
                                      Date End
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      ref="prize_variable_units"
                                      dataField="prize_variable_units"
                                      dataSort={true}
                                      dataFormat={this.toCommasFormat}
                                    >
                                      Prize
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      ref="rule"
                                      dataField="rule"
                                      dataSort={true}
                                      dataFormat={this.renderRaffleRulesText}
                                    >
                                      Rules
                                    </TableHeaderColumn>
                                  </BootstrapTable>
                                </CardBody>
                              </Card>
                            )}
                        </>
                      ) : (
                        <div>No Data</div>
                      )}
                    </>
                  )}
                </Col>
              </Row>
              {this.state.showChartsOnApply && !bucket_loading && (
                <Row>
                  <Col>
                    <Card>
                      <CardHeader>Retention</CardHeader>
                      <CardBody>
                        <Line
                          data={bucketChartData}
                          options={socialChartOpts}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <CardBody>
                        <Nav tabs>
                          <NavItem>
                            <NavLink
                              className={
                                activeTab === "downloads" ? "active" : ""
                              }
                              onClick={() => this.toggleTab("downloads")}
                            >
                              Downloads
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={
                                activeTab === "purchases" ? "active" : ""
                              }
                              onClick={() => this.toggleTab("purchases")}
                            >
                              Purchases
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={
                                activeTab === "revenue" ? "active" : ""
                              }
                              onClick={() => this.toggleTab("revenue")}
                            >
                              Revenue
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={
                                activeTab === "minutes" ? "active" : ""
                              }
                              onClick={() => this.toggleTab("minutes")}
                            >
                              Minutes
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                          <TabPane tabId={activeTab}>
                            <Line
                              data={paramsChartData}
                              options={socialChartOpts}
                            />
                          </TabPane>
                        </TabContent>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}
            </div>
          )}
        </>
      )
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    upliftgamesstats: state.upliftgamesstats.upliftgamesstats,
    user: state.auth.user,
  };
};

export default withReducer(
  "upliftgamesstats",
  reducer
)(connect(mapStateToProps)(UpliftGamesStats));
