import * as Actions from "./store/actions";

import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import React, { Component } from "react";

import FaceTable from "app/views/Face/FaceTable";
import { TapchampsUtils } from "@tapchamps";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "app/store/withReducer";

const tableName = "face";

class Face extends Component {
  constructor(props) {
    super(props);

    this.toggleApproveMulti = this.toggleApproveMulti.bind(this);
    this.toggleRejectMulti = this.toggleRejectMulti.bind(this);

    this.onApproveMultipleClickSubmit =
      this.onApproveMultipleClickSubmit.bind(this);
    this.onRejectMultipleClickSubmit =
      this.onRejectMultipleClickSubmit.bind(this);
    this.setSelected = this.setSelected.bind(this);

    this.state = {
      modalApproveMulti: false,
      modalRejectMulti: false,
      selected: [],
    };
  }
  componentDidMount() {
    this.props.fetch(tableName);
  }
  setSelected(data) {
    this.setState({ selected: data.selected });
  }
  onApproveMultipleClick() {
    this.setState({ modalApproveMulti: true });
  }
  onApproveMultipleClickSubmit() {
    this.toggleApproveMulti();
    this.props.approveFaces(this.state.selected);
    this.setState({ selected: [] });
  }
  toggleApproveMulti() {
    this.setState({
      modalApproveMulti: !this.state.modalApproveMulti,
    });
  }
  onRejectMultipleClick() {
    this.setState({ modalRejectMulti: true });
  }
  onRejectMultipleClickSubmit(values, actions) {
    this.toggleRejectMulti();
    this.props.rejectFaces(this.state.selected);
    this.setState({ selected: [] });
  }
  toggleRejectMulti() {
    this.setState({
      modalRejectMulti: false,
    });
  }
  onSearchChange(searchText, colInfos, multiColumnSearch) {
    console.log(searchText, colInfos, multiColumnSearch);
  }
  onFilterChange(filterObj) {
    this.props.setFilter(tableName, filterObj);
  }
  onSortChange(sortName, sortOrder) {
    this.props.setSort(tableName, sortName, sortOrder);
  }
  onPageChange(page, sizePerPage) {
    this.props.setPaging(tableName, page, sizePerPage);
  }
  onSizePerPageList(sizePerPage) {}

  onDeleteRow(rowKeys, rows) {
    this.props.deleteRows(tableName, rowKeys);
  }
  render() {
    const {
      loading,
      data,
      table: { totalDataSize, sizePerPage, currentPage },
    } = this.props;
    console.log(this.props);

    const canViewUser = TapchampsUtils.hasPermission(
      ["admin", "face-viewer"],
      this.props.user.role
    );
    // const canEdit = TapchampsUtils.hasPermission(
    //   ["admin", "face-editor"],
    //   this.props.user.role
    // );
    return (
      <div className="animated fadeIn">
        {canViewUser && (
          <>
            <Card>
              <CardBody>
                <FaceTable
                  onSearchChange={this.onSearchChange.bind(this)}
                  onFilterChange={this.onFilterChange.bind(this)}
                  onSortChange={this.onSortChange.bind(this)}
                  onPageChange={this.onPageChange.bind(this)}
                  onApproveMultipleClick={this.onApproveMultipleClick.bind(
                    this
                  )}
                  onRejectMultipleClick={this.onRejectMultipleClick.bind(this)}
                  onSizePerPageList={this.onSizePerPageList.bind(this)}
                  processing={this.props.processing}
                  data={Object.values(data)}
                  loading={loading}
                  totalDataSize={totalDataSize}
                  sizePerPage={sizePerPage}
                  currentPage={currentPage}
                  canEdit={true}
                  selected={this.state.selected}
                  setSelected={this.setSelected}
                />
              </CardBody>
            </Card>
            <Modal
              isOpen={this.state.modalApproveMulti !== false}
              toggle={this.toggleApproveMulti}
            >
              <ModalHeader toggle={this.toggleApproveMulti}>
                Approve faces
              </ModalHeader>
              <ModalBody>
                Are you sure you want to approve {this.state.selected.length}{" "}
                faces?
              </ModalBody>
              <ModalFooter>
                <Button
                  color="success"
                  onClick={this.onApproveMultipleClickSubmit}
                >
                  Yes
                </Button>{" "}
                <Button color="secondary" onClick={this.toggleApproveMulti}>
                  No
                </Button>
              </ModalFooter>
            </Modal>
            <Modal
              isOpen={this.state.modalRejectMulti !== false}
              toggle={this.toggleRejectMulti}
            >
              <ModalHeader toggle={this.toggleRejectMulti}>
                Reject Faces
              </ModalHeader>
              <ModalBody>
                Are you sure you want to reject {this.state.selected.length}{" "}
                faces?
              </ModalBody>
              <ModalFooter>
                <Button
                  color="success"
                  onClick={this.onRejectMultipleClickSubmit}
                >
                  Yes
                </Button>{" "}
                <Button color="secondary" onClick={this.toggleRejectMulti}>
                  No
                </Button>
              </ModalFooter>
            </Modal>
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    table: state.face.table[tableName].table,
    data: state.face.table[tableName].data,
    loading: state.face.table[tableName].loading,
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default withReducer(
  "face",
  reducer
)(connect(mapStateToProps, mapDispatchToProps)(Face));
