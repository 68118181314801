const initialState = {
  role: [], //guest
  data: {
    displayName: "",
    photoURL: "",
    email: "",
  },
};

const user = function (state = initialState, action) {
  switch (action.type) {
    case "[USER] SET DATA": {
      return {
        ...initialState,
        ...action.payload,
      };
    }
    case "[USER] REMOVE DATA": {
      return {
        ...initialState,
      };
    }
    case "[USER] LOGGED OUT": {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default user;
