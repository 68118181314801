import * as Actions from "./store/actions";

import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import React, { Component } from "react";

import MarkedTable from "app/views/Marked/MarkedTable";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "app/store/withReducer";

const tableName = "markedusers";

class Marked extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalAdd: false,
    };
    this.toggleAdd = this.toggleAdd.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount() {
    //this.props.dispatch(Actions.getStats());
    this.props.fetch(tableName);
  }
  onSearchChange(searchText, colInfos, multiColumnSearch) {
    console.log(searchText, colInfos, multiColumnSearch);
  }
  onFilterChange(filterObj) {
    this.props.setFilter(tableName, filterObj);
  }
  onSortChange(sortName, sortOrder) {
    this.props.setSort(tableName, sortName, sortOrder);
  }
  onPageChange(page, sizePerPage) {
    this.props.setPaging(tableName, page, sizePerPage);
  }
  onSizePerPageList(sizePerPage) {}

  onInsertBtnClick() {
    this.setState({
      modalAdd: true,
    });
  }

  onDeleteRow(rowKeys, rows) {
    this.props.deleteRows(tableName, rowKeys);
  }
  onSaveCell(row, cellName, cellValue) {
    this.props.updateCell(tableName, row, cellName, cellValue);
  }
  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  toggleAdd() {
    this.setState({
      modalAdd: !this.state.modalAdd,
    });
  }

  async onSubmit(values, actions) {
    await this.props.add(tableName, values, actions);
    this.setState({
      modalAdd: false,
    });
  }

  render() {
    const { loading, isLoading } = this.props;
    if (loading) {
      return loading();
    }

    const {
      data,
      table: { totalDataSize, sizePerPage, currentPage },
    } = this.props;

    return (
      <div className="animated fadeIn">
        <Row>
          <Col>
            <Card>
              <CardHeader>Marked</CardHeader>
              <CardBody>
                {loading ? (
                  <div className="text-center">Loading data</div>
                ) : false ? (
                  <div className="text-center">Empty</div>
                ) : (
                  <MarkedTable
                    onSearchChange={this.onSearchChange.bind(this)}
                    onFilterChange={this.onFilterChange.bind(this)}
                    onSortChange={this.onSortChange.bind(this)}
                    onPageChange={this.onPageChange.bind(this)}
                    onSizePerPageList={this.onSizePerPageList.bind(this)}
                    data={Object.values(data)}
                    loading={isLoading}
                    totalDataSize={totalDataSize}
                    sizePerPage={sizePerPage}
                    currentPage={currentPage}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    table: state.markedusers.table[tableName].table,
    data: state.markedusers.table[tableName].data,
    isLoading: state.markedusers.table[tableName].loading,
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default withReducer(
  "markedusers",
  reducer
)(connect(mapStateToProps, mapDispatchToProps)(Marked));
