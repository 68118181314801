import "./react_dates_overrides.css";

import * as Actions from "./store/actions";

import {
  Button,
  ButtonGroup,
  ButtonToggle,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import React, { Component } from "react";

import { DateRangePicker } from "react-dates";
import { Multiselect } from "multiselect-react-dropdown";
import { TapchampsUtils } from "@tapchamps";
import WidgetAgeBuckets from "app/views/Widgets/WidgetAgeBuckets";
import WidgetByDate from "app/views/Widgets/WidgetByDate";
import WidgetGameAgeBuckets from "app/views/Widgets/WidgetGameAgeBuckets";
import WidgetGameByDate from "app/views/Widgets/WidgetGameByDate";
import _ from "lodash";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "app/store/withReducer";
import exportFromJSON from "export-from-json";

const multiselectStyle = {
  multiselectContainer: {},
  searchBox: {
    //border: "none",
    fontSize: 12,
    height: 35,
    padding: 3,
  },
  chips: {
    fontSize: 10,
  },
  option: {
    fontSize: 12,
  },
};

const multiselectStyleGame = {
  multiselectContainer: {
    minWidth: 300,
  },
  searchBox: {
    //border: "none",
    fontSize: 12,
    height: 35,
    padding: 3,
  },
  chips: {
    fontSize: 10,
  },
  option: {
    fontSize: 12,
  },
};

const GROUP_BY = {
  AGE: 0,
  OS_VERSION: 1,
  DATE: 2,
};

class BreakdownStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      game: "",
      eventnames: [],
      groupBy: 0,
      focusedInput: null,
      startDate: null,
      endDate: null,
      selectedCountry: "",
    };

    this.onGroupByChange = this.onGroupByChange.bind(this);
  }
  componentDidMount() {
    const {
      agebuckets: { startDate, endDate },
    } = this.props;
    this.setState({ startDate, endDate }, () => {
      this.props.dispatch(Actions.getBuckets());
      this.props.dispatch(Actions.getGamesForSelect());
      this.props.dispatch(Actions.getGamesEventsForSelect());
      this.props.dispatch(Actions.getCountriesForSelect());
    });
  }
  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  onCountrySelect(event) {
    if (event.target.value === "Country") {
      this.setState({ selectedCountry: "" });
      this.props.dispatch(
        Actions.getBuckets(
          this.state.game,
          this.state.eventnames,
          this.state.groupBy,
          undefined
        )
      );
    } else {
      this.setState({ selectedCountry: event.target.value });
      this.props.dispatch(
        Actions.getBuckets(
          this.state.game,
          this.state.eventnames,
          this.state.groupBy,
          event.target.value
        )
      );
    }
  }

  onGameSelect(selectedList, selectedItem) {
    this.setState({ game: selectedItem.id, eventnames: [] });

    this.props.dispatch(
      Actions.getBuckets(
        selectedItem.id,
        [],
        this.state.groupBy,
        this.state.selectedCountry
      )
    );
    this.props.dispatch(Actions.getGamesEventsForSelect(selectedItem.id));
  }
  onGameRemove(selectedList, removedItem) {
    this.setState({ game: "", eventnames: [] });

    this.props.dispatch(
      Actions.getBuckets("", [], this.state.groupBy, this.state.selectedCountry)
    );
    this.props.dispatch(Actions.getGamesEventsForSelect(""));
  }
  onEventNameSelect(selectedList, selectedItem) {
    this.setState({
      eventnames: selectedList,
    });
    this.props.dispatch(
      Actions.getBuckets(
        this.state.game,
        selectedList,
        this.state.groupBy,
        this.state.selectedCountry
      )
    );
  }
  onEventNameRemove(selectedList, removedItem) {
    this.setState({
      eventnames: selectedList,
    });
    this.props.dispatch(
      Actions.getBuckets(
        this.state.game,
        selectedList,
        this.state.groupBy,
        this.state.selectedCountry
      )
    );
  }
  onGroupByChange(groupBy) {
    this.setState({ groupBy });
    this.props.dispatch(
      Actions.getBuckets(
        this.state.game,
        this.state.eventnames,
        groupBy,
        this.state.selectedCountry
      )
    );
  }

  onSendReportSubmit(values, actions) {
    const { email } = values;
    this.props.dispatch(
      Actions.sendReportSubmit(
        this.state.game,
        this.state.eventnames,
        this.state.groupBy,
        email,
        actions
      )
    );
  }

  onDownloadReportClick(data) {
    let dataToExport;
    let fields = {
      nop: "Installs",
      atpt: "Average Total Play Time (m)",
      notp: "Number of Times Played",
      anotp: "Average Number of Times Played",
      anos: "Average Number of Sessions",
      apl: "Average Player Level",
      r_retention_ratio_day_0: "Retention D0 (%)",
      r_retention_ratio_day_1: "Retention D1 (%)",
      r_retention_ratio_day_2: "Retention D2 (%)",
      r_retention_ratio_day_3: "Retention D3 (%)",
      r_retention_ratio_day_4: "Retention D4 (%)",
      r_retention_ratio_day_5: "Retention D5 (%)",
      r_retention_ratio_day_6: "Retention D6 (%)",
      r_retention_ratio_day_7: "Retention D7 (%)",
      p: "Purchases",
      pop: "% of People who purchased",
      anogi: "Average Number of Games Installed",
      arg: "Average Revenue Generated",
    };
    let fileName = "breakdownstats";
    if (this.state.groupBy !== GROUP_BY.DATE) {
      dataToExport = data.female
        .concat(data.male)
        .concat(data.other)
        .concat(data.TOTAL);
      fields.gender = "Gender";
      switch (this.state.groupBy) {
        case 0:
          fields.bracket = "Age";
          fileName += "_age";
          break;
        case 1:
          fields.bracket = "OS Version";
          fileName += "_osversion";
          break;
        default:
          break;
      }
    } else {
      dataToExport = data.data;
      fileName += "_date";
      fields.bracket = "Date";
    }
    // eslint-disable-next-line array-callback-return
    this.state.eventnames.map((name) => {
      fields["e_" + name] = name;
      fileName += "_" + name;
    });

    const startDateFormat = new Date(this.state.startDate).toLocaleDateString(
      "en-US"
    );
    const endDateFormat = new Date(this.state.endDate).toLocaleDateString(
      "en-US"
    );

    fileName += "_" + startDateFormat + "_" + endDateFormat;
    if (this.state.game) {
      fileName += "_" + this.state.game;
    }
    fileName += "_" + +new Date();

    dataToExport = dataToExport.filter((d) => !!d);

    exportFromJSON({
      data: dataToExport,
      fileName: fileName,
      fields: fields,
      exportType: exportFromJSON.types.csv,
    });
  }

  render() {
    const { loading } = this.props;
    if (loading) {
      return loading();
    }

    const canViewBreakdown = TapchampsUtils.hasPermission(
      ["admin", "breakdownstats-viewer"],
      this.props.user.role
    );

    const canViewEvents = TapchampsUtils.hasPermission(
      ["admin", "breakdownstats-events-viewer"],
      this.props.user.role
    );

    const canViewOsVersions = TapchampsUtils.hasPermission(
      ["admin", "breakdownstats-osversions-viewer"],
      this.props.user.role
    );

    const canViewByDate = TapchampsUtils.hasPermission(
      ["admin", "breakdownstats-bydate-viewer"],
      this.props.user.role
    );

    let {
      agebuckets: {
        data: bucket_data,
        loading: bucket_loading,
        eventsforselect,
        gamesforselect,
        countriesforselect,
      },
    } = this.props;

    gamesforselect = gamesforselect.map((game) => ({
      ...game,
      displayName: `${game.name} (${game.platform})`,
    }));

    const { game } = this.state;

    let tableToRender;
    if (this.state.groupBy === GROUP_BY.DATE) {
      tableToRender =
        game === undefined || game === "" ? (
          <WidgetByDate
            loading={bucket_loading}
            data={bucket_data}
            selectedEventnames={this.state.eventnames}
            groupBy={this.state.groupBy}
          />
        ) : (
          <WidgetGameByDate
            loading={bucket_loading}
            data={bucket_data}
            selectedEventnames={this.state.eventnames}
            groupBy={this.state.groupBy}
          />
        );
    } else {
      tableToRender =
        game === undefined || game === "" ? (
          <WidgetAgeBuckets
            loading={bucket_loading}
            data={bucket_data}
            selectedEventnames={this.state.eventnames}
            groupBy={this.state.groupBy}
          />
        ) : (
          <WidgetGameAgeBuckets
            loading={bucket_loading}
            data={bucket_data}
            selectedEventnames={this.state.eventnames}
            groupBy={this.state.groupBy}
          />
        );
    }

    return (
      canViewBreakdown && (
        <div className="animated fadeIn">
          <Row>
            <Col>
              <ButtonGroup className="float-left mb-2">
                <ButtonToggle
                  active={this.state.groupBy === GROUP_BY.AGE}
                  onClick={(e) => {
                    this.onGroupByChange(GROUP_BY.AGE);
                  }}
                >
                  Age
                </ButtonToggle>
                {canViewOsVersions && (
                  <ButtonToggle
                    active={this.state.groupBy === GROUP_BY.OS_VERSION}
                    onClick={(e) => {
                      this.onGroupByChange(GROUP_BY.OS_VERSION);
                    }}
                  >
                    OS Version
                  </ButtonToggle>
                )}
                {canViewByDate && (
                  <ButtonToggle
                    active={this.state.groupBy === GROUP_BY.DATE}
                    onClick={(e) => {
                      this.onGroupByChange(GROUP_BY.DATE);
                    }}
                  >
                    Date
                  </ButtonToggle>
                )}
              </ButtonGroup>

              <Button
                color="outline-success"
                className="ml-2"
                disabled={bucket_loading}
                onClick={(e) => {
                  this.onDownloadReportClick(bucket_data);
                }}
              >
                {bucket_loading && <Spinner color="success" size="sm" />}
                {!bucket_loading && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-download"
                    viewBox="0 0 16 16"
                  >
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                  </svg>
                )}{" "}
                Download
              </Button>

              <FormGroup className="float-right mb-2">
                <Multiselect
                  id="search1"
                  selectionLimit={1}
                  loading={loading}
                  style={multiselectStyleGame}
                  options={gamesforselect}
                  placeholder="Game"
                  onSelect={this.onGameSelect.bind(this)}
                  onRemove={this.onGameRemove.bind(this)}
                  displayValue="displayName"
                />
              </FormGroup>
              {canViewEvents && (
                <FormGroup className="float-right mb-2 mr-1">
                  <Multiselect
                    id="search2"
                    loading={loading}
                    style={multiselectStyle}
                    options={_.uniq(eventsforselect.map((obj) => obj.name))}
                    isObject={false}
                    placeholder="Event Name"
                    onSelect={this.onEventNameSelect.bind(this)}
                    onRemove={this.onEventNameRemove.bind(this)}
                    displayValue="name"
                    selectedValues={this.state.eventnames}
                  />
                </FormGroup>
              )}
              <FormGroup className="float-right mb-2 mr-2">
                <Input
                  type="select"
                  id="country"
                  name="country"
                  placeholder="Country"
                  value={this.state.selectedCountry}
                  onChange={this.onCountrySelect.bind(this)}
                >
                  <option value="Country">Country</option>
                  {countriesforselect.map((data, i) => {
                    return (
                      <option key={i} value={data.countrycode}>
                        {data.countrycode}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  {this.state.groupBy === GROUP_BY.AGE
                    ? "Age Breakdown"
                    : this.state.groupBy === GROUP_BY.OS_VERSION
                    ? "OS Version Breakdown"
                    : this.state.groupBy === GROUP_BY.DATE
                    ? "Date Breakdown"
                    : ""}
                  <FormGroup className="float-right mb-0">
                    <DateRangePicker
                      minimumNights={0}
                      isOutsideRange={() => false}
                      startDate={this.state.startDate}
                      startDateId="startDate"
                      endDate={this.state.endDate}
                      endDateId="endDate"
                      small
                      onDatesChange={({ startDate, endDate }) =>
                        this.setState({ startDate, endDate })
                      }
                      focusedInput={this.state.focusedInput}
                      onFocusChange={(focusedInput) => {
                        this.setState({ focusedInput });
                        if (focusedInput === null) {
                          setTimeout(() => {
                            this.props.dispatch(
                              Actions.setDateRange(
                                this.state.game,
                                this.state.eventnames,
                                this.state.groupBy,
                                this.state.startDate,
                                this.state.endDate
                              )
                            );
                          }, 500);
                        }
                      }}
                      orientation={this.state.orientation}
                      openDirection={this.state.openDirection}
                    />
                  </FormGroup>
                </CardHeader>
                <CardBody>{tableToRender}</CardBody>
                {/* <CardFooter>
                  <Formik
                    initialValues={{ email: "" }}
                    validationSchema={validationSchema}
                    onSubmit={this.onSendReportSubmit.bind(this)}
                    render={({
                      values,
                      errors,
                      touched,
                      status,
                      dirty,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      isValid,
                      handleReset,
                      setFieldTouched,
                      setFieldValue,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <FormGroup check inline>
                          <Label check htmlFor="email">
                            Email:
                          </Label>
                          <Input
                            bsSize="sm"
                            id="email"
                            className="mx-1"
                            invalid={touched.email && !!errors.email}
                            autoComplete="off"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                          <Button
                            type="submit"
                            size="sm"
                            color="primary"
                            disabled={isSubmitting}
                          >
                            Send Full Report
                          </Button>
                        </FormGroup>
                      </Form>
                    )}
                  />
                </CardFooter> */}
              </Card>
            </Col>
          </Row>
        </div>
      )
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    agebuckets: state.breakdownstats.agebuckets,
    user: state.auth.user,
  };
};

export default withReducer(
  "breakdownstats",
  reducer
)(connect(mapStateToProps)(BreakdownStats));
