import "react-bootstrap-table/dist//react-bootstrap-table-all.min.css";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Spinner, Button, Row, Col } from "reactstrap";
import React from "react";
import { Link } from "react-router-dom";

const FaceTable = ({
  onSearchChange,
  onSortChange,
  onPageChange,
  onSizePerPageList,
  onFilterChange,
  onClearAllFilters,
  data,
  loading,
  totalDataSize,
  sizePerPage,
  currentPage,
  selected,
  setSelected,
  processing,
  onApproveMultipleClick,
  onRejectMultipleClick,
  canEdit,
}) => {
  const onRowSelect = ({ id }, isSelected) => {
    if (isSelected) {
      setSelected({
        selected: [...selected, id].sort(),
      });
    } else {
      setSelected({
        selected: selected.filter((it) => it !== id),
      });
    }
    return false;
  };

  const onSelectAll = (isSelected, rows) => {
    if (!isSelected) {
      setSelected({ selected: [] });
    } else {
      let sel = selected;
      for (let i = 0; i < rows.length; i++) {
        sel.push(rows[i].id);
      }
      setSelected({
        selected: sel.sort(),
      });
    }
  };

  const photoFormatter = (cell, row) => {
    return (
      <img
        src={`data:image/jpeg;base64,${row.photo}`}
        alt={`userphoto${row.id}`}
        height={350}
      />
    );
  };

  const duplicitiesFormatter = (cell, row) => {
    const duplicities = row.duplicities;

    return (
      <Row>
        {duplicities.map((d) => (
          <Col>
            <Row style={{ justifyContent: "center" }}>
              <Link to={"/users/" + d.externalDatabaseRefID}>
                <img
                  src={`data:image/jpeg;base64,${d.photo}`}
                  alt={`userduplicity${d.externalDatabaseRefID}`}
                  height={350}
                />
              </Link>
            </Row>
          </Col>
        ))}
      </Row>
    );
  };

  const usernameFormat = (cell, row) => {
    return <Link to={"/users/" + row._id}>{row.username}</Link>;
  };

  return (
    <>
      {loading === true && (
        <Spinner className="ml-2" color="primary" size="sm" />
      )}
      <Button
        onClick={onApproveMultipleClick}
        size="sm"
        className="mb-3 mr-3"
        color="success"
        disabled={selected.length < 1 || processing}
      >
        Approve selected
      </Button>
      <Button
        onClick={onRejectMultipleClick}
        size="sm"
        className="mb-3 mr-3"
        color="danger"
        disabled={selected.length < 1 || processing}
      >
        Reject selected
      </Button>
      <BootstrapTable
        trClassName="customBootstrapTable"
        data={loading ? [] : data}
        remote={false}
        pagination={false}
        insertRow={false}
        striped
        deleteRow={false}
        selectRow={{
          mode: "checkbox",
          clickToSelect: true,
          onSelect: onRowSelect,
          onSelectAll: onSelectAll,
          selected: selected,
        }}
        fetchInfo={{ dataTotalSize: totalDataSize }}
        cellEdit={false}
        options={{
          clearSearch: true,
          sizePerPage: sizePerPage,
          onPageChange: onPageChange,
          sizePerPageList: [10, 20, 50, 100],
          page: currentPage,
          onSizePerPageList: onSizePerPageList,
        }}
      >
        <TableHeaderColumn dataField="id" isKey hidden>
          ID
        </TableHeaderColumn>
        <TableHeaderColumn
          width="120px"
          dataField="username"
          dataFormat={usernameFormat}
          dataSort={false}
          editable={canEdit}
          filter={false}
        >
          Username
        </TableHeaderColumn>
        <TableHeaderColumn
          width="210px"
          dataField="photo"
          dataSort={false}
          dataFormat={photoFormatter}
        >
          Photo
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="duplicities"
          dataSort={false}
          dataFormat={duplicitiesFormatter}
        >
          Duplicities
        </TableHeaderColumn>
      </BootstrapTable>
    </>
  );
};

export default FaceTable;
