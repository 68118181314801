import UpliftGamesStats from "./UpliftGamesStats";
import { authRoles } from "app/auth";

export const UpliftGamesStatsConfig = {
  auth: [...authRoles.admin, ...authRoles.breakdownStatsViewer],
  routes: [
    {
      path: "/upliftgamesstats",
      component: UpliftGamesStats,
      name: "Uplift Games Stats",
    },
  ],
};
