import Face from "./Face";
import { authRoles } from "app/auth";

export const FaceConfig = {
  auth: [...authRoles.admin, ...authRoles.faceViewer],
  routes: [
    {
      path: "/face",
      component: Face,
      name: "Face",
    },
  ],
};
