import axios from "axios";
import { fetch } from "./table.actions";
import { showMessage } from "app/store/actions/tapchamps";

export const APPROVE_FACE = "[FACE] APPROVE FACE";
export const REJECT_FACE = "[FACE] REJECT FACE";
export const SET_PROCESSING = "[FACE] SET PROCESSING";

const processingStart = (dispatch) => {
  dispatch({
    type: SET_PROCESSING,
    payload: true,
  });
};
const processingStop = (dispatch) => {
  dispatch({
    type: SET_PROCESSING,
    payload: false,
  });
};

export function approveFaces(ids) {
  return (dispatch, getState) => {
    const request = axios.post("/api/face/approvemulti", ids);
    processingStart(dispatch);
    return request.then((response) => {
      processingStop(dispatch);
      if (response.data.errors !== undefined && response.data.errors > 0) {
        dispatch(
          showMessage({
            message: `${response.data.errors} faces approving failed`,
            type: "error",
          })
        );
      }
      if (response.data.success !== undefined && response.data.success > 0) {
        dispatch(
          showMessage({
            message: `${response.data.success} faces has been approved`,
          })
        );
      }
      return dispatch(fetch("face"));
    });
  };
}

export function rejectFaces(ids) {
  return (dispatch, getState) => {
    processingStart(dispatch);
    const request = axios.post("/api/face/rejectmulti", ids);
    return request.then((response) => {
      processingStop(dispatch);
      if (response.data.errors !== undefined && response.data.errors > 0) {
        dispatch(
          showMessage({
            message: `${response.data.errors} faces reject failed`,
            type: "error",
          })
        );
      }
      if (response.data.success !== undefined && response.data.success > 0) {
        dispatch(
          showMessage({
            message: `${response.data.success} faces has been rejected`,
          })
        );
      }
      return dispatch(fetch("face"));
    });
  };
}
