import { apiSagaRequest, showMessage } from "app/store/actions/tapchamps";

import axios from "axios";

export const GET_UPLIFTGAMESSTATS = "[UPLIFTGAMESSTATS] GET UPLIFTGAMESSTATS";
export const SET_LOADING = "[UPLIFTGAMESSTATS] SET LOADING";
export const GET_GAMES_FOR_SELECT = "[UPLIFTGAMESSTATS] GET GAMES FOR SELECT";
export const GET_COUNTRIES_FOR_SELECT = "[UPLIFTGAMESSTATS] GET COUNTRIES";
export const GET_CHART_DATA = "[UPLIFTGAMESSTATS] GET CHART DATA";
export const SET_DATERANGE = "[UPLIFTGAMESSTATS] SET DATERANGE";

export function setDateRange(gameid, groupby, startDate, endDate) {
  return (dispatch) => {
    dispatch({
      type: SET_DATERANGE,
      payload: { startDate, endDate },
    });
  };
}

export function getGamesForSelect() {
  const request = axios.get("/api/games/forselect", {
    params: { onlyActiveGames: 1, sort: "name" },
  });
  return (dispatch) =>
    request.then((response) => {
      dispatch({
        type: GET_GAMES_FOR_SELECT,
        payload: response.data,
      });
    });
}

export function getCountriesForSelect() {
  const request = axios.get(`/api/countries/allactive`);
  return (dispatch) => {
    request.then((response) => {
      dispatch({
        type: GET_COUNTRIES_FOR_SELECT,
        payload: response,
      });
    });
  };
}

export function setLoading(loading) {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING,
      payload: loading,
    });
  };
}

export function getBuckets(gameid, groupby, fields, countryCode) {
  return (dispatch, getState) => {
    const {
      upliftgamesstats: {
        upliftgamesstats: { startDate, endDate },
      },
    } = getState();
    if (startDate === null || endDate === null) {
      return;
    }
    const dates = {
      startDate: startDate.utc().startOf("day").unix(),
      endDate: endDate.utc().startOf("day").unix(),
    };
    const params = { ...dates };
    if (gameid !== undefined && gameid !== "") {
      params.gameid = gameid;
    }
    if (groupby !== undefined && groupby !== "") {
      params.groupby = groupby;
    }
    if (fields !== undefined && fields !== "") {
      params.fields = fields;
    }
    if (countryCode !== undefined && countryCode !== "") {
      params.countryCode = countryCode;
    }

    dispatch(setLoading(true));
    dispatch(
      apiSagaRequest("API_UPLIFTGAMESSTATS_REQUEST", {
        params,
        method: "get",
        url: "/api/upliftanalysis/stats",
      })
    ).then(
      (response) => {
        if (response.data.error) {
          dispatch(
            showMessage({ message: response.data.error, type: "error" })
          );
          return;
        }

        function mergeData(data, dataPrev, groupby) {
          const merged = {};
          const keys = groupby.split(",");

          const createKey = (item) => keys.map((key) => item[key]).join("_");

          data.sort(
            (a, b) => new Date(a.created_at_date) - new Date(b.created_at_date)
          );
          dataPrev.sort(
            (a, b) => new Date(a.created_at_date) - new Date(b.created_at_date)
          );

          data.forEach((item, index) => {
            const key = createKey(item);
            merged[key] = {
              ...item,
              prev: dataPrev[index] ? { ...dataPrev[index] } : {},
            };
          });

          return Object.values(merged);
        }

        if (response.data.data[0]?.created_at_date) {
          response.data.data = response.data.data.map((r) => {
            r.created_at_date = r.created_at_date?.value;
            return r;
          });
          response.data.dataPrev = response.data.dataPrev.map((r) => {
            r.created_at_date = r.created_at_date?.value;
            return r;
          });
        }

        response.data.mergedData = mergeData(
          response.data.data,
          response.data.dataPrev,
          groupby
        );

        dispatch({
          type: GET_UPLIFTGAMESSTATS,
          payload: response.data,
        });
      },
      (err) => {
        console.log(err);
      }
    );
  };
}

export function getCharts(gameid, countryCode) {
  return (dispatch, getState) => {
    const {
      upliftgamesstats: {
        upliftgamesstats: { startDate, endDate },
      },
    } = getState();
    if (startDate === null || endDate === null) {
      return;
    }
    const dates = {
      startDate: startDate.utc().startOf("day").unix(),
      endDate: endDate.utc().startOf("day").unix(),
    };
    const params = { ...dates };
    if (gameid !== undefined && gameid !== "") {
      params.gameid = gameid;
    }
    if (countryCode !== undefined && countryCode !== "") {
      params.countryCode = countryCode;
    }

    const request = axios.get("/api/upliftanalysis/charts", {
      params,
    });

    request.then((response) => {
      if (response.data.data[0]?.created_at_date) {
        response.data.data = response.data.data.map((r) => {
          r.created_at_date = r.created_at_date?.value;
          return r;
        });
      }
      if (response.data.dataPrev[0]?.created_at_date) {
        response.data.dataPrev = response.data.dataPrev.map((r) => {
          r.created_at_date = r.created_at_date?.value;
          return r;
        });
      }
      if (response.data.paramsData[0]?.created_at_date) {
        response.data.paramsData = response.data.paramsData.map((r) => {
          r.created_at_date = r.created_at_date?.value;
          return r;
        });
      }
      if (response.data.paramsDataPrev[0]?.created_at_date) {
        response.data.paramsDataPrev = response.data.paramsDataPrev.map((r) => {
          r.created_at_date = r.created_at_date?.value;
          return r;
        });
      }

      dispatch({
        type: GET_CHART_DATA,
        payload: response.data,
      });
    });
  };
}
